.send-email-component {
    width: 100vw;
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    background: #F47518;

    .cta_container_solid {
        margin-top: 25px;
        display: flex;
        justify-content: center;
        padding: 10px 20px;
        border-radius: 26px;
        background: var(--primary-blue, #3177C9);
    }

    .cta_text_solid {
        text-align: center;
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: 22px;
        color: var(--White, #FFF);
        display: block;
    }

    .bottom_container {
        margin-top: 50px;
        padding: 0 40px;
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .text_sent {
        color: var(--White, #FFF);
        text-align: center;
        font-family: "Noto Sans", serif;
        font-size: 28px;
        font-style: normal;
        font-weight: 600;
        line-height: 36px; /* 128.571% */
        letter-spacing: -0.613px;
        display: block;
    }

    .text_sent_description {
        margin: 6px 40px 0;
        display: block;
        color: var(--Grayscale-1, #2D3238);
        text-align: center;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 22px; /* 137.5% */
    }

    .cta_container {
        border-radius: 76px;
        background: #23B081;
        box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
        display: flex;
        justify-content: center;
        align-items: center;
        width: 320px;
        height: 59px;
        padding: 12px 16px;
    }

    .swipe_left_right_container {
        margin-top: -30px;
    }

    .cta_container_outline {
        background: transparent;
        display: flex;
        width: 320px;
        height: 59px;
        padding: 12px 16px;
        justify-content: center;
        align-items: center;
        gap: 10px;
        flex-shrink: 0;
        border-radius: 76px;
        border: 1px solid var(--neutral-1, #FFF);
        box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    }

    .cta_text {
        color: var(--neutral-1, #FFF);
        text-align: center;
        font-family: "Noto Sans", serif;
        font-size: 23px;
        font-style: normal;
        font-weight: 600;
        line-height: 22px; /* 95.652% */
        letter-spacing: -0.46px;
        display: block;
    }

    .resend_container {
        display: flex;
        width: 320px;
        height: 59px;
        padding: 12px 16px;
        justify-content: center;
        align-items: center;
        flex-shrink: 0;
        border-radius: 76px;
        border: 1px solid var(--neutral-1, #FFF);
        box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
        background: transparent;
    }
    .resend_text {
        color: var(--neutral-1, #FFF);
        text-align: center;
        font-family: "Noto Sans", serif;
        font-size: 23px;
        font-style: normal;
        font-weight: 600;
        line-height: 22px; /* 95.652% */
        letter-spacing: -0.46px;
    }

    .ant-input {
        box-sizing: border-box;
        margin: 0;
        padding: 4px;
        width: 312px;
        height: 52px;
        flex-shrink: 0;
        border-radius: 10px;
        background: var(--neutral-1, #FFF);
        position: relative;
        display: inline-block;
        color: var(--Grayscale-2, #6B7178);
        font-family: "Noto Sans", serif;
        font-size: 22px;
        font-style: normal;
        font-weight: 400;
        line-height: 22px; /* 100% */
        letter-spacing: -0.44px;
    }

    .carousel_container {
        margin: 50px 0 0;
        width: 100vw;
        height: 60vh;
        overflow-y: hidden;
        overflow-x: scroll;
        scroll-snap-type: x mandatory;
        scroll-behavior: smooth;
        display: grid;
        grid-template-columns: repeat(10, 1fr);
    }

    .carousel_item {
        width: 100vw;
        height: 60vh;
        scroll-snap-stop: always;
        scroll-snap-align: center;
        list-style-type: none;
        display: flex;
        align-items: center;
        justify-content: center;
    }


    .letter_text_container {
        border-radius: 8px;
        background: #FFFAEF;
        margin: 0 5%;
        padding: 10px;
        max-width: 800px;
        font-family: "Oooh Baby", serif;
        white-space: break-spaces;
        box-sizing: border-box;
        display: inline-block;
        overflow-y: scroll;
        justify-content: start;

    }

    .letter_text {
        color: #000;
        font-style: italic;
        font-size: 8px;
        line-height: 1;
    }

    @media screen and (min-width: 320px) and (min-height: 600px) {
        .letter_text {
            font-size: 10px;
            line-height: 1.2;
        }
        .letter_text_container {
            margin: 0 5%;
            padding: 12px;
        }
    }

    @media screen and (min-width: 330px) and (min-height: 700px) {
        .letter_text {
            font-size: 12px;
            line-height: 1.3;
        }
        .letter_text_container {
            margin: 0 8%;
            padding: 20px;
        }
    }

    @media screen and (min-width: 390px) and (min-height: 800px) {
        .letter_text {
            font-size: 16px;
            line-height: 1.5;
        }
        .letter_text_container {
            margin: 0 8%;
            padding: 24px;
        }
    }

    @media screen and (min-width: 600px) {
        .letter_text {
            font-size: 18px;
            line-height: 1.5;
        }
        .letter_text_container {
            margin: 0 10%;
            padding: 24px;
        }
    }
}


