.NewRecipeDrawerComponent {
  background: transparent;
  width: min-content;

  .seeRecipeButton {
    width: 150px;
    height: 50px;
    flex-shrink: 0;
    border-radius: 59px;
    background: #F47518;
    color: #EEF5FF;
    text-align: center;
    font-family: "Noto Sans", serif;
    font-size: 21px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }

}

.recipeDrawerContainer {
  background: transparent;

  .ant-drawer-wrapper-body {
     position: relative;

    .ant-drawer-header {
      display: none;
      flex: 0 1;
      align-items: center;
      padding: 16px;
      font-size: 16px;
      line-height: 22px;
      border-bottom: 1px solid #f0f0f0;
    }

    .ant-drawer-header.ant-drawer-header-close-only {
      position: absolute;
      top: 0;
      left: 0;
      z-index: 3;
      border: none;
      scale: 1;
      background: transparent;
    }
  }



  .ant-drawer-body {
    padding: 0;
    background-image: url("../../../public/recipe_bg.png");
    background-repeat: repeat;
    align-items: center;
    display: flex;
    flex-direction: column;
  }

  .back_text {
    color: #FFF;
    font-family: "Noto Sans", serif;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
  }

  .arrow_icon {
    width: 8px;
    height: 16px;
  }

  .back_button {
    position: relative;
    width: 93.896px;
    height: 38.552px;
    border-radius: 59px;
    border: 1px solid #FFF;
    background: rgba(217, 217, 217, 0.20);
    margin: 20px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }


  .save_button {
    position: absolute;
    top: calc(100% - 100px);
    left: 50%;
    transform: translateX(-50%);
    width: 318px;
    height: 46px;
    flex-shrink: 0;
    border-radius: 59px;
    background: #FEFEFE;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .button_text {
    color: #3E8F64;
    text-align: center;
    font-family: "Noto Sans", serif;
    font-size: 21px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    display: block;
  }

  .image {
    width: 100vw;
    max-width: 800px;
  }

  .back_button_container {
    width: 100vw;
    display: flex;
  }

  .back_button_text {
    color: #FFF;
    font-family: "Noto Sans", serif;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    letter-spacing: -0.613px;
    display: block;
  }
}
