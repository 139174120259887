.intro4_container {
    width: 100%;
    height: 100vh;
    display: inline-flex;
    flex-direction: column;
    align-items: center;
    background-image: url("../../../public/diya_off_bg.gif");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}

.diaya_off_text_container {
    padding: 25% 40px 0;
}

.reminder_text_container {
    position: absolute;
    bottom: 320px;
    margin: 0;
}

.diya_ellipse_container {
    position: absolute;
    bottom: 180px;
    margin: 0;
}

.text_reminder {
    color: #FFF;
    text-align: center;
    font-family: "Noto Sans", serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 15.124px; /* 94.525% */
    letter-spacing: -0.222px;
    display: block;
    opacity: 0.8;
}

.diaya_off_text {
    color: #FFF;
    text-align: center;
    font-size: 30px;
    font-weight: 600;
    line-height: 42px;
    display: block;
    animation: textFadeIn 5s linear;
}

.diaya_on_text {
    color: #FFF;
    text-align: center;
    font-size: 30px;
    font-weight: 600;
    line-height: 42px;
    display: block;
    animation: textFadeIn2 6s linear;
}

.intro5_container {
    width: 100%;
    height: 100vh;
    display: inline-flex;
    flex-direction: column;
    align-items: center;
    //animation: backgroundColor 5s linear;
    background-image: url("../../../public/diya_on_bg.gif");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}

.intro5_click_area {
    position: absolute;
    bottom: 50px;
    left: 50%;
    transform: translateX(-50%);
    width: 300px;
    height: 300px;
    background: transparent;
}

@keyframes backgroundColor {
    0% {
        background: linear-gradient(180deg, #381103 69.97%, #D9D9D9 100%);
    }
    100% {
        background: linear-gradient(180deg, #000 0%, #A72004 36.46%, #E2880E 71.87%);
    }
}

@keyframes textFadeIn {
    0% {
        opacity: 0.1;
    }
    100% {
        opacity: 1.0;
    }
}

@keyframes textFadeIn2 {
    0% {
        opacity: 0;
    }
    50% {
        opacity: 0;
    }
    100% {
        opacity: 1.0;
    }
}
