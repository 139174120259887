@import '~antd/dist/antd.css';

:root {
  --general-visit: #6236ff;
  --initial-visit: #36c5cc;
  --follow-up-visit: #4278c3;
  --additional-visit: #ee7c81;
  --tech-visit: #ffb310;
  --mnt-visit: #83c858;
  --tech-onboarding-visit: #cf51ab;
  --uc-blue: #3177C9;
  --uc-light-blue: #5a92d4;
  --uc-blue-secondary: #E9F1FA;
  --blue: #2aa1ff;
  --dark-blue: #00479A;
  --primary-blue:#3177C9;
  --dark-red: #CB1C1C;
  --secondary-red: #DA6453;
  --secondary-orange: #EF973B;
  --due-date-warning: #FF982D;
  --background-gray: #F4F6F8;
  --background-gray-a03: rgba(244, 246, 248, 0.3);
  --background-even: #fafafa;
  --gray: #2D3238;
  --secondary-gray: #6B7178;
  --text-gray-primary: #6B7178;
  --text-gray: #595959;
  --text-darker-gray: #333333;
  --bg-gray: #484848;
  --bg-light-gray: #F5F5F5;
  --border-gray: #d9d9d9;
  --light-gray: #E7EAED;
  --secondary-yellow: #F2C81A;
  --default-radius: 4px;
  --secondary-radius: 8px;
  --middle-table-radius: 18px;
  --small-table-radius: 9px;
  --green: #00875A;
  --primary-green: #00C8BC;
  --secondary-green: #3BA767;
  --tertiary-green: #E5F9F8;
  --gray-scale-1: #2D3238;
  --gray-scale-2: #6B7178;
  --gray-scale-3: #B5B9BE;
  --gray-scale-4: #E7EAED;
  --gray-scale-5: #F4F6F8;
  --gray-scale-6: #FAFBFC;
  --gray-scale-8: #FBFBFB;
  --common-padding: 35px 35px 40px 31px;
  --common-card-padding: 20px 16px 20px 26px;
  --confirmation-modal-width: 378px;
  --neutral: #8C8C8C;
  --secondary-purple-tint: #F9F2FF;
}


* {
  font-size: 16px;
}

$margin-limit: 10;

@mixin margin-list {
  @for $i from 1 through $margin-limit {
    .m#{$i}0 {
      margin: #{$i}0px;
    }

    .mt#{$i}0 {
      margin-top: #{$i}0px;
    }

    .mt#{$i} {
      margin-top: #{$i}px;
    }

    .mb#{$i} {
      margin-bottom: #{$i}px;
    }

    .mr#{$i}0 {
      margin-right: #{$i}0px;
    }

    .mb#{$i}0 {
      margin-bottom: #{$i}0px;
    }

    .ml#{$i}0 {
      margin-left: #{$i}0px;
    }

    .my#{$i}0 {
      margin-top: #{$i}0px;
      margin-bottom: #{$i}0px;
    }

    .mx#{$i}0 {
      margin-right: #{$i}0px;
      margin-left: #{$i}0px;
    }

    .m#{$i}0i {
      margin: #{$i}0px;
    }

    .mt#{$i}0i {
      margin-top: #{$i}0px !important;
    }

    .mr#{$i}0i {
      margin-right: #{$i}0px !important;
    }

    .mb#{$i}0i {
      margin-bottom: #{$i}0px !important;
    }

    .ml#{$i}0i {
      margin-left: #{$i}0px !important;
    }

    .my#{$i}0i {
      margin-top: #{$i}0px !important;
      margin-bottom: #{$i}0px !important;
    }

    .mx#{$i}0i {
      margin-right: #{$i}0px !important;
      margin-left: #{$i}0px !important;
    }
  }
}

@include margin-list;

$padding-limit: 10;

@mixin padding-list {
  @for $i from 1 through $padding-limit {
    .p#{$i}0 {
      padding: #{$i}0px;
    }

    .p#{$i}5 {
      padding: #{$i}5px;
    }

    .pt#{$i}0 {
      padding-top: #{$i}0px;
    }

    .pr#{$i}0 {
      padding-right: #{$i}0px;
    }

    .pb#{$i}0 {
      padding-bottom: #{$i}0px;
    }

    .pl#{$i}0 {
      padding-left: #{$i}0px;
    }

    .py#{$i}0 {
      padding-top: #{$i}0px;
      padding-bottom: #{$i}0px;
    }

    .py#{$i}5 {
      padding: #{$i}5px;
    }

    .px#{$i}0 {
      padding-right: #{$i}0px;
      padding-left: #{$i}0px;
    }

    .px#{$i}5 {
      padding: #{$i}5px;
    }

    .p#{$i}0i {
      padding: #{$i}0px !important;
    }

    .pt#{$i}0i {
      padding-top: #{$i}0px !important;
    }

    .pr#{$i}0i {
      padding-right: #{$i}0px !important;
    }

    .pb#{$i}0i {
      padding-bottom: #{$i}0px !important;
    }

    .pl#{$i}0i {
      padding-left: #{$i}0px !important;
    }

    .py#{$i}0i {
      padding-top: #{$i}0px !important;
      padding-bottom: #{$i}0px !important;
    }

    .px#{$i}0i {
      padding-right: #{$i}0px !important;
      padding-left: #{$i}0px !important;
    }
  }
}

@include padding-list;


.flex {
  display: flex;
  gap: 5px;
}

.inline-flex {
  display: flex;
  gap: 5px;
}

.flex-wrap {
  flex-wrap: wrap;
}

.fr-reverse {
  flex-direction: row-reverse;
}

.fc-reverse {
  flex-direction: column-reverse;
}

.gap0 {
  gap: 0px;
}

.gap1 {
  gap: 5px;
}

.gap2 {
  gap: 10px;
}

.gap3 {
  gap: 20px;
}

.gap4 {
  gap: 30px;
}

.gap5 {
  gap: 40px;
}

.f1 {
  flex: 1;
}

.fd-c {
  flex-direction: column;
}

.fd-c.reverse {
  flex-direction: column-reverse;
}

.fd-r.reverse {
  flex-direction: row-reverse;
}

.flex-start,
.jc-s {
  justify-content: flex-start;
}

.flex-end,
.jc-e {
  justify-content: flex-end;
}

.jc-sb {
  justify-content: space-between;
}

.jc-c {
  justify-content: center;
}

.ai-c {
  align-items: center;
}

.ai-s {
  align-items: flex-start;
}

.ai-e {
  align-items: flex-end;
}

.ai-bl {
  align-items: baseline;
}

.flex-center,
.flex-c {
  display: flex;
  justify-content: center;
}

.flex-center-center,
.flex-cc {
  display: flex;
  justify-content: center;
  align-items: center;
}

.flex-wrap,
.f-w {
  flex-wrap: wrap;
}

$width-limit: 10;

@mixin width-list {
  @for $i from 1 through $width-limit {
    .w#{$i}0 {
      width: #{$i}0 + "%";
    }

    .w#{$i}0i {
      width: #{$i}0 + "%" !important;
    }
  }
}

@include width-list;

.bold {
  font-weight: bold !important;
}

.text-center {
  text-align: center;
}

.h100 {
  height: 100%;
}

.h50 {
  height: 50%;
}

.center {
  text-align: center;
  justify-content: center;
  align-items: center;
  vertical-align: middle;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 16px;
}

.container {
  overflow-x: hidden;
  overflow-y: scroll;
  height: 100vh;
  width: 100%;
  scroll-snap-type: y mandatory;
  scroll-behavior: smooth;
}

.container.height {
  height: auto;
}

.pageContainer {
  width: 100%;
  height: 100vh;
  scroll-snap-stop: always;
  scroll-snap-align: center;
  position: relative;
  background: #FFF;
  overflow-x: hidden;
  overflow-y: scroll;
}

.bottom_img {
  position: absolute;
  bottom: 100px;
  left: 50%;
  transform: translateX(-50%);
}

.bottom_btn {
  position: absolute;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
}

.top_btn {
  position: absolute;
  top: 20px;
  left: 50%;
  transform: translateX(-50%);
}

.text_italic {
  color: #fff;
  font-size: 23px;
  line-height: 33px;
  font-weight: 500;
  font-style: italic;
  text-align: left;
  white-space: pre-line;
  display: block;
}

.text {
  color: #000;
  font-family: "Noto Sans", serif;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  white-space: pre-line;
  display: block;
}

.text_black {
  color: #030005;
  font-size: 24px;
  line-height: 34px;
  font-weight: 500;
  font-style: normal;
  text-align: left;
  white-space: pre-line;
  display: block;
}

.text_black_italic {
  color: #030005;
  font-size: 28px;
  line-height: 39px;
  font-weight: 600;
  font-style: italic;
  text-align: left;
  white-space: pre-line;
  display: block;
}

.text_small {
  color: #FFF;
  font-family: "Noto Sans", serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  text-align: center;
  display: block;
}

::-webkit-scrollbar {
  display: none;
}

.progress_bar_container {
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
}

.indicator_dot_active {
  width: 8px;
  height: 8px;
  flex-shrink: 0;
  border-radius: 8px;
  border: 1px solid var(--Grayscale-4, #E7EAED);
  background: #FFF;
}

.indicator_dot_inactive {
  width: 8px;
  height: 8px;
  flex-shrink: 0;
  border-radius: 8px;
  background: rgba(45, 50, 56, 0.70);
}

@keyframes panZoom {
  0% {
    background-position: 0% 0%;
    background-size: 100% 100%;
  }
  50% {
    background-position: 0% 0%;
    background-size: 110% 110%;
  }
  100% {
    background-position: 0% 0%;
    background-size: 120% 120%;
  }
}


.box {
  position: absolute;
  top: 30%;
  left: 50%;
  transform-style: preserve-3d;
  transition: transform var(--transition-time);
  pointer-events: all;
}

.box .item {
  width: 100%;
  height: 100%;
  border: 1px solid red;
  cursor: pointer;
}

.arrows_container {
  position: absolute;
  top: 50%;
  width: 100vw;
  display: flex;
  flex-direction: row;
  justify-content: center;
  background: transparent;
  padding: 10px 0;
  pointer-events: all;
}

.arrow_button{
  display: flex;
  padding: 10px 130px;
  border-width: 0;
  background: transparent;
}

.arrow_icon {
  width: 18px;
  height: 36px;
}
