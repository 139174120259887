.NoPageComponent {
  width: 100vw;
  height: calc(100vh);
  background: url("../../public/loading_bg.jpg");
  display: flex;
  align-items: start;
  justify-content: center;
  padding-top: 100px;

  .text_404 {
    color: var(--Grayscale-3, #B5B9BE);
    font-family: "Noto Sans", serif;
    font-size: 126.851px;
    font-style: normal;
    font-weight: 500;
    line-height: 139.537px; /* 110% */
    text-transform: capitalize;
    display: block;
    text-align: center;
  }

  .text {
    color: var(--Grayscale-3, #B5B9BE);
    font-family: "Noto Sans", serif;
    font-size: 28.115px;
    font-style: normal;
    font-weight: 500;
    line-height: 103.088px; /* 366.667% */
    text-transform: capitalize;
    display: block;
    text-align: center;
  }

}
