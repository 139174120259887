.intro4-component {
    .background {
        position: absolute;
        transform-origin: top left;
        width: 100%;
        height: 100%;
        background-image: url('../../../public/page4_bg.jpg');
        background-size: cover;
        background-position: center;
    }

    .text_container_white {
        position: absolute;
        top: calc(50% - 180px);
        left: calc(50% - 180px);
        width: 360px;
        height: 360px;
        border-radius: 360px;
        padding: 0 40px;
        background: #231868;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }

    .theme_bar {
        width: 90px;
        height: 6px;
        flex-shrink: 0;
        background: #FFF;
    }

    .text_bold {
        color: var(--neutral-1, #FFF);
        text-align: center;
        font-feature-settings: 'clig' off, 'liga' off;
        font-family: "Noto Sans", serif;
        font-size: 28px;
        font-style: normal;
        font-weight: 600;
        line-height: 36px; /* 128.571% */
        display: block;
    }
}
