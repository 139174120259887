.recipe-component {
    .background {
        position: absolute;
        width: 100%;
        height: 100%;
        background-size: contain;
        background-image: url("../../../public/recipe_bg.png");
    }

    .carousel_container {
        position: absolute;
        top: 50%;
        margin-top: 110px;
        display: flex;
        width: 100%;
        justify-content: center;
        align-items: center;
        flex-direction: column;
    }

    .title {
        color: var(--White, #FFF);
        text-align: center;
        text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
        font-family: "Noto Sans", serif;
        font-size: 30px;
        font-style: normal;
        font-weight: 700;
        line-height: 30px; /* 100% */
        letter-spacing: -0.613px;
    }

    .description {
        color: var(--White, #FFF);
        text-align: center;
        text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
        font-family: "Noto Sans", serif;
        font-size: 20px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        letter-spacing: -0.613px;
        padding: 0 40px;
        max-width: 600px;
    }

    .bottom_container {
        margin-top: 25px;
        display: flex;
        flex-direction: column;
        justify-content: center;
    }

    .horizontal_snap {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        margin: 0 0;
        height: 400px;
        display: flex;
        flex-direction: row;
        overscroll-behavior-x: contain;
        scroll-snap-type: x mandatory;
    }

    .horizontal_snap_item {
        width: 100%;
        scroll-snap-align: center;
    }

    .bottom_btn {
        position: absolute;
        bottom: 90px;
        left: 50%;
        transform: translateX(-50%);
    }
}

