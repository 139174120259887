.container {
  overflow-x: hidden;
  overflow-y: scroll;
  height: 100vh;
  width: 100%;
  scroll-snap-type: y mandatory;
  scroll-behavior: smooth;
}

.container.height {
  height: auto;
}

.pageContainer {
  width: 100%;
  height: 100vh;
  scroll-snap-stop: always;
  scroll-snap-align: center;
  position: relative;
  background: #FFF;
  overflow-x: hidden;
  overflow-y: scroll;
}

.bottom_img {
  position: absolute;
  bottom: 100px;
  left: 50%;
  transform: translateX(-50%);
}

.bottom_btn {
  position: absolute;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
}

.top_btn {
  position: absolute;
  top: 20px;
  left: 50%;
  transform: translateX(-50%);
}

.text_italic {
  color: #fff;
  font-size: 23px;
  line-height: 33px;
  font-weight: 500;
  font-style: italic;
  text-align: left;
  white-space: pre-line;
  display: block;
}

.text {
  color: #000;
  font-family: "Noto Sans", serif;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  white-space: pre-line;
  display: block;
}

.text_black {
  color: #030005;
  font-size: 24px;
  line-height: 34px;
  font-weight: 500;
  font-style: normal;
  text-align: left;
  white-space: pre-line;
  display: block;
}

.text_black_italic {
  color: #030005;
  font-size: 28px;
  line-height: 39px;
  font-weight: 600;
  font-style: italic;
  text-align: left;
  white-space: pre-line;
  display: block;
}

.text_small {
  color: #FFF;
  font-family: "Noto Sans", serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  text-align: center;
  display: block;
}

::-webkit-scrollbar {
  display: none;
}

.progress_bar_container {
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
}

.indicator_dot_active {
  width: 8px;
  height: 8px;
  flex-shrink: 0;
  border-radius: 8px;
  border: 1px solid var(--Grayscale-4, #E7EAED);
  background: #FFF;
}

.indicator_dot_inactive {
  width: 8px;
  height: 8px;
  flex-shrink: 0;
  border-radius: 8px;
  background: rgba(45, 50, 56, 0.70);
}

@keyframes panZoom {
  0% {
    background-position: 0% 0%;
    background-size: 100% 100%;
  }
  50% {
    background-position: 0% 0%;
    background-size: 110% 110%;
  }
  100% {
    background-position: 0% 0%;
    background-size: 120% 120%;
  }
}


.box {
  position: absolute;
  top: 30%;
  left: 50%;
  transform-style: preserve-3d;
  transition: transform var(--transition-time);
  pointer-events: all;
}

.box .item {
  width: 100%;
  height: 100%;
  border: 1px solid red;
  cursor: pointer;
}

.arrows_container {
  position: absolute;
  top: 50%;
  width: 100vw;
  display: flex;
  flex-direction: row;
  justify-content: center;
  background: transparent;
  padding: 10px 0;
  pointer-events: all;
}

.arrow_button{
  display: flex;
  padding: 10px 130px;
  border-width: 0;
  background: transparent;
}

.arrow_icon {
  width: 18px;
  height: 36px;
}
