.intro3-component {
    .background {
        position: absolute;
        transform-origin: top left;
        top: 15%;
        width: 100%;
        height: 85%;
        background-image: url('../../../public/page3_bg.png');
        background-size: cover;
        background-position: center;
        //animation: panZoom 60s infinite linear;
    }

    .text_container_white {
        position: absolute;
        top: 0;
        left: calc(50% - 175px);
        width: 350px;
        height: 440px;
        padding: 50px 0 0 30px;
        opacity: 0;
        background: var(--White, #FFF);
        animation: fadeIn3 1s forwards;
    }

    .theme_bar {
        width: 90px;
        height: 6px;
        flex-shrink: 0;
        background: #873693;
    }

    .text_bold {
        color: var(--neutral-13, #000);
        font-feature-settings: 'clig' off, 'liga' off;
        font-family: "Noto Sans", serif;
        font-size: 28px;
        font-style: normal;
        font-weight: 600;
        line-height: 36px; /* 128.571% */
        text-align: left;
        white-space: pre-line;
        display: block;
    }

    @keyframes fadeIn3 {
        0% {
            top: 0;
            left: calc(50% - 175px);
            width: 0;
            height: 0;
            opacity: 0;
        }
        100% {
            top: 0;
            left: calc(50% - 175px);
            width: 350px;
            height: 440px;
            opacity: 1;
        }
    }
}
