.LoadingComponent {
  width: 100vw;
  height: 100vh;
  background: url("../../public/loading_bg.jpg");
  display: flex;
  align-items: center;
  justify-content: center;

  .ant-progress {
    color: red;
    margin: 10px;
    display: inline-block;
  }
  .ant-progress-outer {
    padding: 0;
    margin: 0;
    width: 200px;
  }

  .ant-progress-inner {
    position: relative;
    width: 100%;
    background-color: transparent;
    border-radius: 100px;
    height: 20px;
    border: 3px solid var(--White, #FFF);
  }

  .ant-progress-bg {
    position: relative;
    margin-left: -1px;
    background-color: var(--White, #FFF);
    border-radius: 10px 0 0 10px;
    transition: all 0.4s cubic-bezier(0.08, 0.82, 0.17, 1) 0s;
  }

  .ant-progress-text {
    display: none;
  }

  .text {
    color: #FFF;
    font-family: "Noto Sans", serif;
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    display: block;
    text-align: center;
  }

  .loading_images{
    position: absolute;
    opacity: 0;
  }

}
