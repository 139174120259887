.intro1-component {

    .background {
        position: absolute;
        width: 100%;
        height: 100%;
        background-image: url('../../../public/page1_bg.png');
        background-size: cover;
        background-position: center;
    }

    .text_container {
        position: absolute;
        top: 12%;
        left: 0;
        width: 100%;
        padding: 0 50px;
    }

    .text_bold {
        color: var(--neutral-1, #FFF);
        font-family: "Noto Sans", serif;
        font-size: 28px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        text-align: left;
        white-space: pre-line;
        display: block;
    }

    .bottom_btn {
        position: absolute;
        bottom: 80px;
        left: 50%;
        transform: translateX(-50%);
    }
}

